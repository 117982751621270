<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Promotor de vendas"
            name="Promotor"
            placeholder="Promotor"
            v-model="notaSaida.nomePromotor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="notaSaida.praca"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a)"
            name="Vendedor(a)"
            placeholder="Vendedor(a)"
            v-model="notaSaida.nomeVendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="CPF"
            name="CPF"
            placeholder="CPF"
            v-model="notaSaida.cpf"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data de saída"
            name="dataSaida"
            placeholder="Data de saída"
            v-model="notaSaida.dataSaidaString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de retorno"
            name="dataRetorno"
            placeholder="Data de retorno"
            v-model="notaSaida.dataRetornoString"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <select-saidas></select-saidas>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn outline @click="salvar" color="primary">Salvar</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoSaida } from "../../servicos/servicoSaida"
import SelectSaidas from "./selectSaidasPorVendedor"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoSaida = new ServicoSaida()
export default {
  components: {
    SelectSaidas,
    ProgressCircular
  },
  data() {
    return {
      id: null,
      snackbar: false,
      text: "",
      notaSaida: "",
      idSaidaUnificar: null
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSaida.buscarPorIdResumido(this.id).then(res => {
        this.notaSaida = res.data
        serverBus.$emit("vendedorSaida", this.notaSaida.idVendedor)
      })
    }
    serverBus.$on("saidaSelecionada", value => {
      this.idSaidaUnificar = value.idSaida
    })
  },
  methods: {
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    },
    salvar() {
      this.abrirProgressCircular()
      servicoSaida.salvarUnificar(parseInt(this.id), parseInt(this.idSaidaUnificar)).then(res => {
        if (res.status === 200 && res.data.length === undefined) {
          this.fecharProgressCircular()
          this.voltar()
        } else {
          this.fecharProgressCircular()
          this.snackbar = true
          this.text = res.data[0].title + " - " + res.data[0].detail
        }
      })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
