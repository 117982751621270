<template>
  <v-flex>
    <v-autocomplete
      label="Saída para unificar"
      placeholder="Selecione..."
      :rules="[() => !!codSaida || 'Campo obrigatório']"
      return-object
      v-model="codSaida"
      :items="saidas"
      item-value="idSaida"
      @input="setSelected"
      item-text="textoExibir"
      required
    ></v-autocomplete>
    <v-snackbar :color="'success'" v-model="snackbar" :timeout="timeout" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoSaida } from "../../servicos/servicoSaida"
const servicoSaida = new ServicoSaida()
export default {
  data: () => ({
    dialog: false,
    codSaida: null,
    saidas: [],
    snackbar: false,
    timeout: 2000,
    codVendedor: ""
  }),
  created() {
    serverBus.$on("vendedorSaida", value => {
      this.codVendedor = value
      this.carregarVendedores()
    })
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("saidaSelecionada", value)
    },
    carregarVendedores() {
      servicoSaida.buscarSaidasDoVendedor(this.codVendedor).then(res => {
        if (res.status === 200) {
          this.saidas = res.data
        }
      })
    }
  }
}
</script>
